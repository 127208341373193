<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-data-table
        id="TenderListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :loading="loading"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.jobId }}</td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            />
            <img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td v-if="props.item.isClosed">Closed</td>
          <td v-if="!props.item.isClosed">Open</td>

          <td style="word-break: break-word">
            {{ props.item.sourceLocation }}
          </td>
          <td style="word-break: break-word">
            {{ props.item.destinationLocation }}
          </td>
          <td>{{ getText(truckTypes, props.item.truckType) }}</td>
          <td>{{ props.item.requiredTrucks }}</td>
          <td>{{ props.item.remainingTruck }}</td>
          <td>
            {{ props.item.amountForTransporter }}
            {{ props.item.transporterCurrency }}
          </td>
          <td>
            {{ getTime3(props.item.pickupDate) }} {{ props.item.pickupTime }}
          </td>
          <td>{{ getTime(props.item.closedDate) }}</td>
          <td>
            <v-layout>
              <Bids :bid="props.item" @refresh-tender-list="refreshList()" />
              <ReopenJobs
                v-permissions="'reopen-job'"
                v-if="props.item.isClosed && props.item.remainingTruck > 0"
                :detail="props.item"
                v-on:reopenError="reopenErrors"
                v-on:refreshJob="refresh"
                :payload="payload"
              />
              <WithDrawJobs
                v-permissions="'withdraw-job'"
                v-if="!props.item.isClosed"
                :detail="props.item"
                v-on:refreshJobs="refreshWith"
                v-on:error="withdrawErrors"
              />
            </v-layout>
          </td>
        </template>
      </v-data-table>
      <pagination
        v-if="this.jobFilter"
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="listApiUrl"
        :callback="callback"
        :componentKey="componentKey"
        :action="`tender`"
        v-on:pagination-load="checkLoading"
        :payload="payload"
        :type="type"
      />
    </v-card-text>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="6000"
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </v-card>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import { tenderList } from "@/constants/datatable-headers.js";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");

import Bids from "./Bids";
import WithDrawJobs from "./WithDrawJobs";
import { tenderSectionApiUrl } from "@/constants/api-urls.js";
import ReopenJobs from "./ReopenJobs";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import pagination from "@/components/Pagination/pagination";
import { debuglog } from "util";
import { StorageKeys, TruckTypes } from "../../constants/constants";
import Loading from "vue-loading-overlay";
export default {
  name: "tenderComponents",
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.payload.workingCountry = JSON.stringify(workingCountry);
      this.payload = {
        jobModel: this.tabFilter,
        filter: this.jobFilter,
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.payload.workingCountry = this.workingCountry;
      this.payload.pageNo = "0";
    });
  },
  components: {
    pagination,
    SuccessDialog,
    ErrorBox,
    Bids,
    WithDrawJobs,
    ReopenJobs,
  },
  props: {
    reset: Boolean,
    tabFilter: String,
    loading: Boolean,
    jobFilter: String,
    searchText: String,
  },
  watch: {
    pagination(val) {
      if (val["sortBy"] === "truckReq") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "requiredTrucks";
          this.payload["sortType"] = -1;

          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "requiredTrucks";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "RemainQuantity") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "remainingTruck";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "remainingTruck";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "startPrice") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "amountForTransporter";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "amountForTransporter";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "Loading") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "startDate";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "startDate";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "Closed") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "closedDate";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "closedDate";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        this.componentKey = this.componentKey + 1;
      }
      deep: true;
    },

    searchText: function (n, o) {
      this.$eventBus.$emit("on-load");
      if (n != "") {
        this.$set(this.payload, "jobId", n);
        this.payload.searchType = "jobId";
      } else {
        this.payload = {
          jobModel: this.tabFilter,
          filter: this.jobFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    jobFilter: function (n, o) {
      if (n != "") {
        this.$eventBus.$emit("on-load");
        this.$set(this.payload, "filter", n);
      } else {
        this.$eventBus.$emit("on-load");

        this.payload = {
          jobModel: this.tabFilter,
          filter: this.jobFilter,
          workingCountry: this.workingCountry,
        };
      }
    },

    tabFilter: function (n, o) {
      this.$eventBus.$emit("on-load");
      if (n != "") {
        this.$set(this.payload, "jobModel", n);
      } else {
        this.payload = {
          jobModel: this.tabFilter,
          filter: this.jobFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    reset: function (n, o) {
      if (n == true) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          jobModel: this.tabFilter,
          filter: this.jobFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      workingCountry: null,
      listApiUrl: tenderSectionApiUrl.tenderList,
      isHidden: false,
      truckTypes: TruckTypes,
      action: [
        { title: "Details" },
        { title: "Mix panel" },
        { title: "Bids" },
        { title: "Reopen" },
        { title: "withdraw" },
      ],
      type: 1,
      dialog: false,
      dialog2: false,
      processing: false,
      componentKey: 0,
      search: "",
      isNoSearchResult: false,
      payload: {},
      pagination: {},
      pages: 1,

      totalPage: 0,
      totalPages: 0,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },

      headers: tenderList,
      documentData: [],
      items: [],
      executives: [],
      selectedExecutive: null,
      currentIndexForCustomer: null,
    };
  },
  methods: {
    refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    checkLoading(event) {
      if (event) {
        this.$emit("on-load");
      } else {
        // this.$emit("job-load", false);
        this.$emit("off-load");
      }
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    refreshWith(event) {
      for (var i = 0; i < this.items.length; i++) {
        if (event.jobId === this.items[i].jobId) {
          this.items.splice(i, 1);
        }
      }
    },

    refresh(event) {
      for (var i = 0; i < this.items.length; i++) {
        if (event.jobId === this.items[i].jobId) {
          this.items[i].isClosed = false;
          this.componentKey = this.componentKey + 1;
        }
      }
    },
    page(event) {
      this.pages = event;
    },

    withdrawErrors(event) {
      this.x.error = event;
    },
    reopenErrors(event) {
      this.x.error = event;
    },
    goToProfile(Id) {
      this.$router.push(`/jobs/${Id}`);
    },
    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime4(time) {
      return moment(time).format("LT");
    },
    getTime(date) {
      return moment(date).format("ll LT");
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = false;
    },
    openDocument(data) {
      this.getCustomer(data.userId);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTimes(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },

    // getTime(date) {
    //   return moment(date).format("DD/MM/YYYY HH:mm");
    // },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      //this.$emit("off-load");
      this.$emit("fixed-count", res.totalFixedData);
      this.$emit("bid-count", res.totalBidData);
      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
#TenderListTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#TenderListTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.v-table__overflow {
  width: 100%;
  /* overflow-x: auto; */
  overflow-x: inherit !important;
  overflow-y: inherit !important;
  /* overflow-y: hidden; */
}
</style>

<style scoped>
.fix-width-td {
  word-break: break-word;
}
.v-card {
  padding: 15px;
}
.v-icon.outlined {
  border: 1px solid orange;
  color: orange;
  border-radius: 20%;
  height: 10px;
  width: 20px;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
